import SearchOutlined from '@mui/icons-material/SearchOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';

export type Props = {
  searchString: string;
  showArchived: boolean;
  onSearchStringChange: (searchTerm: string) => void;
  onShowArchivedChange: () => void;
};

export const TagsSearch = ({
  searchString,
  showArchived,
  onSearchStringChange,
  onShowArchivedChange,
}: Props): JSX.Element => {
  return (
    <Stack justifyContent="space-between" direction="row" mb={1}>
      <TextField
        autoFocus
        placeholder="Search tags"
        size="small"
        sx={{ width: '220px' }}
        InputProps={{
          endAdornment: <SearchOutlined />,
        }}
        onChange={(e) => onSearchStringChange(e.target.value)}
        value={searchString}
      />
      <FormControlLabel
        control={
          <Switch checked={showArchived} onChange={onShowArchivedChange} />
        }
        label="show archived tags"
        labelPlacement="start"
      />
    </Stack>
  );
};
