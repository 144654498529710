import { Tag as TagSchema } from '@automata/api/apiSchemas';
import { Tag } from '@automata/ui';
import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import ArchiveTagPopover from 'components/TagManager/ArchiveTagPopover';
import { DeleteTagPopover } from 'components/TagManager/DeleteTagPopover';
import EditTagPopover from 'components/TagManager/EditTagPopover';
import { useWorkspaceID } from 'hooks/useWorkspaceID';

export type Props = {
  tag: TagSchema;
  refetchTags: () => void;
};

export const TagsListRow = ({
  tag,
  refetchTags,
}: Props): JSX.Element | null => {
  const workspaceID = useWorkspaceID();

  if (!workspaceID) return null;

  return (
    <Stack width="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Tag label={tag.name} color={tag.color} />
        <Stack direction="row" gap={1}>
          <EditTagPopover
            tag={tag}
            workspaceID={workspaceID}
            refetchTags={refetchTags}
          />
          {!tag.is_archived && (
            <ArchiveTagPopover
              tag={tag}
              workspaceID={workspaceID}
              refetchTags={refetchTags}
              variant="archive"
            />
          )}
          {tag.is_archived && (
            <>
              <ArchiveTagPopover
                tag={tag}
                workspaceID={workspaceID}
                refetchTags={refetchTags}
                variant="unarchive"
              />
              <DeleteTagPopover
                tag={tag}
                workspaceID={workspaceID}
                refetchTags={refetchTags}
              />
            </>
          )}
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  );
};
