import Head from 'next/head';
import { SnackbarProvider } from '@automata/rexports/notistack';
import { env } from '@automata/utils';
import CssBaseline from '@mui/material/CssBaseline';
import { ModalProvider } from 'contexts/ModalContext';
import { UnleashProvider } from 'contexts/UnleashProvider';
import { StyledEngineProvider } from '@mui/material/styles';
import { CustomThemeProvider } from 'contexts/ThemeContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Layout } from 'components/Layout';
import {
  LocalizationProvider,
  AdapterDayjs,
} from '@automata/rexports/x-date-pickers-pro';
import { FeedbackWidget } from 'components/FeedbackWidget';
import { AblyProvider } from 'components/AblyProvider';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { AppProps } from 'next/app';
import { registerMuiLicense } from '@automata/config-mui-x-license';
import { initialiseDatadog } from 'utils/initialiseDatadog';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { UserProvider, withPageAuthRequired } from '@auth0/nextjs-auth0/client';

registerMuiLicense();
initialiseDatadog();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const AppHead = (): JSX.Element => (
  <Head>
    <title>{env('NEXT_PUBLIC_APP_NAME')}</title>
    <link rel="icon" type="image/png" href="/favicon.png" sizes="16x16" />
  </Head>
);

const StylingProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => (
  <StyledEngineProvider>
    <CustomThemeProvider>
      <CssBaseline />
      {children}
    </CustomThemeProvider>
  </StyledEngineProvider>
);

const App = ({ children }: { children: JSX.Element }): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AppHead />
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        preventDuplicate
      >
        <UnleashProvider>
          <ErrorBoundary>
            <StylingProvider>
              <ModalProvider>
                <AblyProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Layout>{children}</Layout>
                    <FeedbackWidget />
                  </LocalizationProvider>
                </AblyProvider>
              </ModalProvider>
            </StylingProvider>
          </ErrorBoundary>
        </UnleashProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  );
};

const AppWithAuth = withPageAuthRequired(App);

const AuthWrap = ({ Component, pageProps }: AppProps) => {
  // ErrorPage is a special case where we don't want to wrap it with Auth
  if (Component.displayName === 'ErrorPage') {
    return (
      <>
        <AppHead />
        <StyledEngineProvider>
          <Component {...pageProps} />
        </StyledEngineProvider>
      </>
    );
  }
  return (
    <UserProvider>
      <AppWithAuth>
        <Component {...pageProps} />
      </AppWithAuth>
    </UserProvider>
  );
};

export default AuthWrap;
