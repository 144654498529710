import { useMemo, useState } from 'react';
import { TextField, TrackedButton } from '@automata/ui';
import { Tag } from '@automata/api/apiSchemas';
import Stack from '@mui/material/Stack';

export type Props = {
  existingTags: Tag[];
  addTag: (name: string, onSuccess: () => void) => void;
};

export const TagCreator = ({ existingTags, addTag }: Props): JSX.Element => {
  const [newTagName, setNewTagName] = useState('');

  const existingTagNames = useMemo(
    () => existingTags.map((tag) => tag.name.toLowerCase()),
    [existingTags]
  );
  const isDuplicateTag = useMemo(
    () => existingTagNames.includes(newTagName.toLowerCase()),
    [existingTagNames, newTagName]
  );

  const isButtonDisabled = newTagName === '' || isDuplicateTag;

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <TextField
        sx={{ width: '220px' }}
        label="New tag"
        value={newTagName}
        onChange={(e) => setNewTagName(e.target.value)}
        placeholder="Enter new tag name here"
        id="tag-creator"
      />
      <TrackedButton
        variant="text"
        trackLabel="tag-manager-add-new"
        disabled={isButtonDisabled}
        sx={{ mt: 2.5 }}
        onClick={() => {
          addTag(newTagName, () => setNewTagName(''));
        }}
      >
        Add new tag
      </TrackedButton>
    </Stack>
  );
};
