import Tooltip from '@mui/material/Tooltip';
import { TagFilterIcon } from './TagFilterIcon';
import { TrackedIconButton } from '../TrackedIconButton';

export type TagFilterButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const TagFilterButton = ({
  onClick,
}: TagFilterButtonProps): JSX.Element | null => {
  return (
    <Tooltip title="Filter by tags">
      <TrackedIconButton
        sx={{ position: 'relative' }}
        onClick={onClick}
        aria-label="Tag Filter Button"
        trackLabel="filterByTagPopover.open"
      >
        <TagFilterIcon />
      </TrackedIconButton>
    </Tooltip>
  );
};

export default TagFilterButton;
