import React, { useState } from 'react';
import { Tag } from '@automata/api/apiSchemas';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useDeleteTag } from '@automata/api/apiComponents';
import {
  DeleteButton,
  ErrorDisplay,
  TrackedButton,
  Spinner,
} from '@automata/ui';
import { defaultTheme } from '@automata/mui-theme';

export type Props = {
  tag: Tag;
  workspaceID: string;
  refetchTags: () => void;
};

export const DeleteTagPopover = ({
  tag,
  workspaceID,
  refetchTags,
}: Props): JSX.Element | null => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const popoverId = `delete-tag-popover-${tag.id}`;

  const trackLabelVariant = `tag-delete-${tag.id}`;

  const { mutate: deleteTag, isLoading, error } = useDeleteTag();

  const handleDeleteTag = () => {
    deleteTag(
      {
        pathParams: { tagID: tag.id, workspaceID },
      },
      {
        onSuccess: () => {
          setAnchorEl(null);
          refetchTags();
        },
      }
    );
  };

  return (
    <>
      <Popover
        marginThreshold={32}
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack direction="column" gap={2} p={2}>
          <Typography variant="h6">Delete {tag.name}</Typography>
          <Typography variant="body2">This action cannot be undone.</Typography>
          <Stack gap={2} direction="row">
            {error && (
              <ErrorDisplay error={error} userMessage="failed to delete tag" />
            )}
            {isLoading && <Spinner />}
            {!isLoading && !error && (
              <>
                <TrackedButton
                  onClick={() => {
                    setAnchorEl(null);
                  }}
                  variant="outlined"
                  trackLabel="cancel-delete-tag"
                >
                  Cancel
                </TrackedButton>
                <TrackedButton
                  onClick={handleDeleteTag}
                  variant="contained"
                  color="error"
                  trackLabel="confirm-delete-tag"
                >
                  Delete
                </TrackedButton>
              </>
            )}
          </Stack>
        </Stack>
      </Popover>
      <DeleteButton
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        trackLabelVariant={trackLabelVariant}
        sx={{ color: defaultTheme.palette.neutral.main }}
      />
    </>
  );
};

export default DeleteTagPopover;
