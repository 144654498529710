import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import { TrackedIconButton, TrackingButtonProps } from '../TrackedIconButton';
import { forwardRef } from 'react';

export type ArchiveButtonProps = Omit<
  TrackingButtonProps,
  'trackLabel' | 'aria-label' | 'onClick' | 'variant'
> & {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: 'archive' | 'unarchive';
  trackLabelVariant?: string;
};

export const ArchiveButton = forwardRef<HTMLButtonElement, ArchiveButtonProps>(
  (props, ref): JSX.Element | null => {
    const {
      onClick,
      trackLabelVariant,
      variant = 'archive',
      ...trackedIconButtonProps
    } = props;

    const label = trackLabelVariant
      ? `${trackLabelVariant}-${variant}-button`
      : `${variant}-button`;

    return (
      <TrackedIconButton
        {...trackedIconButtonProps}
        ref={ref}
        onClick={onClick}
        trackLabel={label}
        aria-label={`${variant}-button`}
      >
        {variant === 'unarchive' ? (
          <UnarchiveOutlinedIcon />
        ) : (
          <ArchiveOutlinedIcon />
        )}
      </TrackedIconButton>
    );
  }
);

ArchiveButton.displayName = 'ArchiveButton';
