import { Tag as TagSchema } from '@automata/api/apiSchemas';
import {
  GetWorkspaceTagsError,
  GetWorkflowDetailsError,
} from '@automata/api/apiComponents';
import { Stack, Typography } from '@mui/material';
import {
  ErrorDisplay,
  SelectedTagsList,
  Spinner,
  TagAutoComplete,
} from '../../';

type TagSelectorContainerProps = {
  children: React.ReactNode;
  title?: JSX.Element;
};

const TagSelectorContainer = ({
  children,
  title = <Typography variant="body1">Tags</Typography>,
}: TagSelectorContainerProps): JSX.Element => {
  return (
    <Stack spacing={2}>
      {title}
      {children}
    </Stack>
  );
};

export type TagSelectorProps = {
  error: GetWorkspaceTagsError | GetWorkflowDetailsError | null;
  isLoading: boolean;
  allTags: TagSchema[];
  resourceTags: TagSchema[];
  removeTag: (tag: TagSchema) => void;
  addTag: (tag: TagSchema) => void;
  title?: JSX.Element;
};

export const TagSelector = ({
  error,
  isLoading,
  allTags,
  resourceTags,
  removeTag,
  addTag,
  title,
}: TagSelectorProps): JSX.Element | null => {
  // TODO(RT, 13/06/2024): alter error message to be more specific once clarified with design
  if (error) {
    return (
      <TagSelectorContainer title={title}>
        <ErrorDisplay error={error} userMessage="Unable to fetch tags" />
      </TagSelectorContainer>
    );
  }

  if (isLoading) {
    return (
      <TagSelectorContainer title={title}>
        <Spinner />
      </TagSelectorContainer>
    );
  }

  return (
    <TagSelectorContainer title={title}>
      <SelectedTagsList tags={resourceTags} onDelete={removeTag} />
      <TagAutoComplete tags={allTags} onChange={addTag} />
    </TagSelectorContainer>
  );
};

export default TagSelector;
