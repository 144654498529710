import React, { useEffect, useState } from 'react';
import { Tag as TagSchema } from '@automata/api/apiSchemas';
import { Autocomplete, TextField, Box, Typography } from '@mui/material';
import { Tag } from '../Tag';
import { selectRandomCategoricalColor } from '@automata/utils';

export type TagAutoCompleteProps = {
  tags: TagSchema[];
  onChange: (tag: TagSchema) => void;
};

export const TagAutoComplete = ({
  tags,
  onChange,
}: TagAutoCompleteProps): JSX.Element | null => {
  const [inputValue, setInputValue] = useState('');
  const [selectedTag, setSelectedTag] = useState<TagSchema | null>(null);
  const [label, setLabel] = useState('Create or select a tag');
  const [newTag, setNewTag] = useState<TagSchema>({
    id: '',
    name: '',
    color: 'marineGreen',
    is_archived: false,
  });

  const resetInput = () => {
    setInputValue('');
    setSelectedTag(null);
  };

  const handleOptionSelect = (option: TagSchema) => {
    if (option.id === '') return;

    onChange(option);
    setSelectedTag(option);
    resetInput();
  };

  const handleCustomTagCreation = () => {
    if (inputValue.trim() === '') return;

    onChange(newTag);
    resetInput();
  };

  const filterOptions = (
    options: TagSchema[],
    state: { inputValue: string }
  ) => {
    const filtered = options.filter((option) =>
      option.name.toLowerCase().includes(state.inputValue.toLowerCase())
    );

    if (
      state.inputValue !== '' &&
      !filtered.some((option) => option.name === state.inputValue)
    ) {
      filtered.unshift({
        id: '',
        name: state.inputValue,
        color: 'marineGreen',
        is_archived: false,
      });
    }

    return filtered;
  };

  const handleChange = (
    _event: React.ChangeEvent<{}>,
    value: TagSchema | null
  ) => {
    if (value) {
      handleOptionSelect(value);
    }
  };

  useEffect(() => {
    if (inputValue.trim() === '') return;

    setNewTag({
      id: '',
      name: inputValue,
      color: selectRandomCategoricalColor(),
      is_archived: false,
    });
  }, [inputValue]);

  return (
    <Autocomplete
      id="tags"
      size="small"
      options={tags}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option.name ?? ''}
      value={selectedTag}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleCustomTagCreation();
            }
          }}
          onFocus={() => {
            setLabel('');
          }}
          onBlur={() => {
            setLabel('Create or select a tag');
          }}
        />
      )}
      renderOption={(props, option) => {
        if (option.id === '') {
          return (
            <Box
              component="li"
              {...props}
              onClick={() => handleCustomTagCreation()}
              key={option.id}
            >
              <Typography sx={{ mr: 1 }}>Create </Typography>
              <Tag
                color={newTag.color}
                label={newTag.name}
                style={{ cursor: 'pointer' }}
              />
            </Box>
          );
        }
        return (
          <Box
            component="li"
            {...props}
            onClick={() => handleOptionSelect(option)}
            key={option.id}
          >
            <Tag
              color={option.color}
              label={option.name}
              style={{ cursor: 'pointer' }}
            />
          </Box>
        );
      }}
    />
  );
};

export default TagAutoComplete;
