import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import { Typography } from '../Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomAccordion = styled(MuiAccordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    boxShadow: 'none',
    backgroundImage: 'none',
    '&:before': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiAccordionSummary-root': {
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    minHeight: '64px !important',
  },
  '& .MuiAccordionDetails-root': {
    paddingInline: 0,
    paddingBlock: theme.spacing(4),
  },
}));

export interface CustomAccordionProps extends AccordionProps {
  summary: string;
}

export const Accordion = ({
  summary,
  children,
  ...props
}: CustomAccordionProps) => {
  return (
    <CustomAccordion {...props}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </CustomAccordion>
  );
};

export default Accordion;
