import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

export type DataFieldProps = {
  value: string;
  id: string;
};

const DataFieldContainer = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.grey[100],
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  },
  '& .MuiOutlinedInput-input': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '8px', // Ensure some space at the end
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

/**
 * Represents a data field component.
 *
 * @component
 * @example
 * ```tsx
 * import { DataField } from './DataField';
 *
 * const MyComponent = () => {
 *   return (
 *    <>
 *      <InputLabel htmlFor={id}>
 *        Label
 *      </InputLabel>
 *      <DataField value="The data" id="datafield-id" />
 *    </>
 *   );
 * };
 * ```
 */
export const DataField = ({ value, id }: DataFieldProps): JSX.Element => {
  return (
    <FormControl>
      <DataFieldContainer
        size="small"
        id={id}
        value={value}
        fullWidth
        InputProps={{
          sx: {
            fontSize: 'h6.fontSize',
            fontWeight: 'h6.fontWeight',
            height: '40px',
          },
        }}
      />
    </FormControl>
  );
};

export default DataField;
