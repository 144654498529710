import { Typography } from '@automata/ui';
import { Tag } from '@automata/api/apiSchemas';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import { TagsListRow } from './TagsListRow';
import Stack from '@mui/material/Stack';

export type Props = {
  tags: Tag[];
  refetchTags: () => void;
  searchString: string;
  title?: string;
};

const TagsList = ({
  tags,
  refetchTags,
  title,
  searchString,
}: Props): JSX.Element | null => {
  const filteredTags = tags.filter((tag) =>
    tag.name.toLowerCase().includes(searchString.toLowerCase())
  );

  if (filteredTags.length === 0) return null;

  return (
    <Stack>
      {title && <Typography variant="subtitle2">{title}</Typography>}
      <List disablePadding>
        {filteredTags.length > 0 &&
          filteredTags.map((tag: Tag) => (
            <ListItem disableGutters disablePadding key={tag.id}>
              <TagsListRow tag={tag} refetchTags={refetchTags} />
            </ListItem>
          ))}
      </List>
    </Stack>
  );
};

export default TagsList;
