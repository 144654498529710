import { TagIconButton, Typography } from '../..';
import { Tag } from '../Tag';
import { Tag as TagSchema } from '../../../api/apiSchemas';
import { defaultTheme } from '@automata/mui-theme';
import Box from '@mui/material/Box';
import AddOutlined from '@mui/icons-material/AddOutlined';
import ButtonBase from '@mui/material/ButtonBase';

export type TagsListHorizontalProps = {
  tags: TagSchema[];
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  limit?: number;
  variant?: 'list' | 'default';
};

export const TagsListHorizontal = ({
  tags,
  onClick,
  limit = 3,
  variant = 'default',
}: TagsListHorizontalProps): JSX.Element => {
  if (!tags || tags.length === 0) {
    if (variant === 'list') {
      return (
        <ButtonBase onClick={onClick}>
          <AddOutlined sx={{ color: defaultTheme.palette.info.main, mr: 1 }} />
          <Typography
            variant="subtitle2"
            color={defaultTheme.palette.info.main}
          >
            add tag
          </Typography>
        </ButtonBase>
      );
    }
    return <TagIconButton badgeCount={0} onClick={onClick} />;
  }

  const showTags: TagSchema[] = tags.slice(0, limit);
  const badgeCount = tags.length > limit ? tags.length : 0;
  return (
    <Box display="flex" gap={1} alignItems="center">
      {showTags.map((tag) => {
        return (
          <ButtonBase key={tag.id} onClick={onClick}>
            <Tag
              key={tag.id}
              label={tag.name}
              color={tag.color}
              role="listitem"
              aria-label="tag"
              isClickable
            />
          </ButtonBase>
        );
      })}
      {tags.length > limit && <Typography>...</Typography>}
      <TagIconButton badgeCount={badgeCount} onClick={onClick} />
    </Box>
  );
};

export default TagsListHorizontal;
