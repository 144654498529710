import Add from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { MouseEventHandler, forwardRef } from 'react';

interface CreateButtonProps {
  onConfirmAction: MouseEventHandler<HTMLButtonElement>;
}

export const CreateButton = forwardRef<HTMLButtonElement, CreateButtonProps>(
  function CreateButton(
    { onConfirmAction, ...props }: CreateButtonProps,
    ref
  ): JSX.Element {
    return (
      <Fab
        ref={ref}
        color="primary"
        onClick={onConfirmAction}
        size="small"
        aria-label="create"
        {...props}
      >
        <Add />
      </Fab>
    );
  }
);
