import { Typography } from '@automata/ui';
import Link from 'next/link';
import { SxStyles } from 'types/global';
import { RouteKey } from 'GlobalRoutes';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { useRoute } from 'hooks/useRoute';
import { ButtonProps } from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';

const styles = {
  link: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    alignItems: 'flex-start',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
  },
  menuItem: {
    textWrap: 'wrap',
    px: 1,
    width: '100%',
    textAlign: 'left',
  },
} satisfies SxStyles;

type BaseProps = {
  title: string;
  description: string;
};

type WithOnClick = {
  onClick: () => void;
  route?: never;
};

type WithRoute = {
  route: RouteKey;
  onClick?: never;
};

export type Props = BaseProps & (WithOnClick | WithRoute);

export const SettingsMenuItem = ({
  title,
  description,
  route,
  onClick,
}: Props): JSX.Element | null => {
  const href = useRoute(route);

  const buttonProps: ButtonProps = {
    onClick,
    sx: styles.button,
  };
  const linkProps: ButtonProps = {
    LinkComponent: Link,
    href,
    sx: styles.link,
  };
  const conditionalProps = route ? linkProps : buttonProps;

  return (
    <>
      <Divider variant="middle" />
      <Box m={1}>
        <ButtonBase {...conditionalProps}>
          <Typography variant="overline" mx={1}>
            {title}
          </Typography>
          <MenuItem sx={styles.menuItem}>
            <Typography variant="subtitle2" color="primary">
              {description}
            </Typography>
          </MenuItem>
        </ButtonBase>
      </Box>
    </>
  );
};
