import Stack from '@mui/material/Stack';
import { styled } from '@mui/material';
import InputBase from '@mui/material/InputBase';

export const InputsWrap = styled(Stack)`
  // values to line up with MUI inputs
  box-sizing: border-box;
  padding: 8.5px ${(p) => p.theme.spacing(1)};
  height: 40px;

  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 0.05em;
  border: 1px solid ${(p) => p.theme.palette.divider};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  &:focus-within {
    outline: 1px solid ${(p) => p.theme.palette.primary.main};
    border-color: ${(p) => p.theme.palette.primary.main};
    &.error {
      outline: 1px solid ${(p) => p.theme.palette.error.main};
      border-color: ${(p) => p.theme.palette.error.main};
      &:hover {
        border-color: ${(p) => p.theme.palette.error.main};
      }
    }
  }
  &:hover {
    border-color: ${(p) => p.theme.palette.common.black};
    &.error {
      border-color: ${(p) => p.theme.palette.divider};
    }
  }
`;

export const InputBaseStyled = styled(InputBase)`
  & input {
    text-align: center;
    width: 1.8em;
    &::placeholder {
      font-family: monospace;
    }
  }
`;
