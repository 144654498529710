import React, { useMemo, useState } from 'react';
import { Tag } from '@automata/api/apiSchemas';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useArchiveTag, useUnarchiveTag } from '@automata/api/apiComponents';
import {
  ArchiveButton,
  ErrorDisplay,
  TrackedButton,
  Spinner,
} from '@automata/ui';
import { defaultTheme } from '@automata/mui-theme';

export type Props = {
  tag: Tag;
  workspaceID: string;
  refetchTags: () => void;
  variant: 'archive' | 'unarchive';
};

export const ArchiveTagPopover = ({
  tag,
  workspaceID,
  refetchTags,
  variant,
}: Props): JSX.Element | null => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const popoverId = `${variant}-tag-popover-${tag.id}`;

  const trackLabelVariant = `tag-${variant}-${tag.id}`;

  const pathParams = { workspaceID: workspaceID, tagID: tag.id };

  const {
    mutate: archiveTag,
    isLoading: isArchiveLoading,
    error: isArchiveError,
  } = useArchiveTag();

  const {
    mutate: unarchiveTag,
    isLoading: isUnarchiveLoading,
    isError,
  } = useUnarchiveTag();

  const handleConfirm = () => {
    if (variant === 'archive') {
      archiveTag(
        {
          pathParams,
        },
        {
          onSuccess: () => {
            setAnchorEl(null);
            refetchTags();
          },
        }
      );
    } else {
      unarchiveTag(
        {
          pathParams,
        },
        {
          onSuccess: () => {
            setAnchorEl(null);
            refetchTags();
          },
        }
      );
    }
  };

  const error = useMemo(() => {
    return isArchiveError || isError;
  }, [isArchiveError, isError]);

  const isLoading = useMemo(() => {
    return isArchiveLoading || isUnarchiveLoading;
  }, [isArchiveLoading, isUnarchiveLoading]);

  const variantText = variant === 'archive' ? 'Archive' : 'Unarchive';

  return (
    <>
      <Popover
        marginThreshold={32}
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack direction="column" gap={2} p={2}>
          <Typography variant="body2">{variantText} this tag?</Typography>
          <Stack gap={2} direction="row">
            {error && (
              <ErrorDisplay
                error={error}
                userMessage={`failed to ${variant} tag`}
              />
            )}
            {isLoading && <Spinner />}

            {!isLoading && !error && (
              <>
                <TrackedButton
                  onClick={() => {
                    setAnchorEl(null);
                  }}
                  variant="outlined"
                  trackLabel={`cancel-${variant}-tag`}
                >
                  Cancel
                </TrackedButton>
                <TrackedButton
                  onClick={handleConfirm}
                  variant="contained"
                  color="primary"
                  trackLabel={`confirm-${variant}-tag`}
                >
                  {variant}
                </TrackedButton>
              </>
            )}
          </Stack>
        </Stack>
      </Popover>
      <ArchiveButton
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        trackLabelVariant={trackLabelVariant}
        sx={{ color: defaultTheme.palette.neutral.main }}
        variant={variant}
      />
    </>
  );
};

export default ArchiveTagPopover;
