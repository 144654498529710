import { Tag } from '../Tag';
import { Tag as TagSchema } from '../../../api/apiSchemas';
import { Box } from '@mui/material';
import { Typography } from '../..';

export type SelectedTagsListProps = {
  tags: TagSchema[];
  onDelete: (tag: TagSchema) => void;
};

export const SelectedTagsList = ({
  tags,
  onDelete,
}: SelectedTagsListProps): JSX.Element => {
  if (tags.length > 0) {
    return (
      <Box display="flex" flexWrap="wrap" gap={1}>
        {tags.map((tag) => (
          <Tag
            key={tag.id}
            label={tag.name}
            color={tag.color}
            onDelete={() => onDelete(tag)}
            data-testid="selected-tag"
          />
        ))}
      </Box>
    );
  } else {
    return <Typography variant="body2">No tags selected currently</Typography>;
  }
};

export default SelectedTagsList;
