import { forwardRef } from 'react';
import { Typography } from '../Typography';
import { defaultTheme } from '@automata/mui-theme';
import Stack from '@mui/material/Stack';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { v4 as uuidv4 } from 'uuid';
import FormControl from '@mui/material/FormControl';
import { SxStyles } from '../types';

const styles = {
  readOnly: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: defaultTheme.palette.grey[100],
      fontWeight: defaultTheme.typography.fontWeightMedium,
      color: defaultTheme.palette.text.primary,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  fullHeight: {
    flexGrow: 1,
    '& .MuiInputBase-root': {
      height: '100% !important',
    },
    '& .MuiInputBase-input': {
      height: '100% !important',
      resize: 'none',
    },
  },
} satisfies SxStyles;

export type TextFieldProps = Omit<MuiTextFieldProps, 'size'> & {
  readOnly?: boolean;
  fullHeight?: boolean;
};

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function forwarded(
    { id = `textField-${uuidv4()}`, label, readOnly, fullHeight, ...props },
    ref
  ) {
    return (
      <Stack
        width={props.fullWidth ? '100%' : 'inherit'}
        sx={fullHeight ? { height: '100%' } : undefined}
      >
        <InputLabel htmlFor={id}>
          <Typography
            variant="overline"
            color={defaultTheme.palette.text.primary}
          >
            {label}
          </Typography>
        </InputLabel>
        <FormControl sx={fullHeight ? { height: '100%' } : undefined}>
          <MuiTextField
            id={id}
            {...props}
            ref={ref}
            size="small"
            sx={{
              ...props.sx,
              ...(readOnly ? styles.readOnly : {}),
              ...(fullHeight ? styles.fullHeight : {}),
            }}
          />
        </FormControl>
      </Stack>
    );
  }
);
