import { SvgIcon } from '@mui/material';

export const TagFilterIcon = () => (
  <SvgIcon>
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.41661 4.45763L9.67936 2.94651L10.7329 4L13.5613 4L11.0437 1.4824C10.5602 0.99893 9.8524 0.816013 9.19523 1.00471L3.8009 2.55361C3.17818 2.73241 2.69139 3.2192 2.51259 3.84191L0.963694 9.23624C0.774998 9.89342 0.957915 10.6012 1.44138 11.0847L11.2942 20.9375C12.0285 21.6718 13.219 21.6718 13.9532 20.9375L18.8907 16H16.0623L12.6237 19.4386L2.9055 9.72038L4.41661 4.45763Z"
        fill="#414141"
      />
      <path
        d="M7.86818 7.90923C8.41889 7.35852 8.41889 6.46564 7.86818 5.91493C7.31747 5.36422 6.42459 5.36422 5.87388 5.91493C5.32317 6.46564 5.32317 7.35852 5.87388 7.90923C6.42459 8.45994 7.31747 8.45994 7.86818 7.90923Z"
        fill="#414141"
      />
      <path
        d="M13.9989 11.9999H16.9989V13.9999H13.9989V11.9999Z"
        fill="#414141"
      />
      <path
        d="M9.99894 5.99992H20.9989V7.99992H9.99894V5.99992Z"
        fill="#414141"
      />
      <path
        d="M11.9989 8.99992H18.9989V10.9999H11.9989V8.99992Z"
        fill="#414141"
      />
    </svg>
  </SvgIcon>
);
