import React, { useState } from 'react';
import { Tag } from '@automata/api/apiSchemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { schemas } from '@automata/api/zod';
import { useUpdateTag } from '@automata/api/apiComponents';
import {
  EditButton,
  ErrorDisplay,
  TrackedButton,
  Spinner,
  TextField,
  Select,
} from '@automata/ui';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { defaultTheme } from '@automata/mui-theme';
import { categorical } from '@automata/mui-theme/src/colors';
import MenuItem from '@mui/material/MenuItem';
import { useController, useForm } from 'react-hook-form';

export type Props = {
  tag: Tag;
  workspaceID: string;
  refetchTags: () => void;
};

export const EditTagPopover = ({
  tag,
  workspaceID,
  refetchTags,
}: Props): JSX.Element | null => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const colourOptions = Object.entries(categorical);

  const { register, control, handleSubmit, formState } = useForm<Tag>({
    defaultValues: {
      name: tag.name,
      color: tag.color,
    },
    resolver: zodResolver(schemas.EditTag),
  });

  const { field: typeField } = useController({
    name: 'color',
    control,
  });

  const popoverId = `edit-tag-popover-${tag.id}`;

  const trackLabelVariant = `tag-edit-${tag.id}`;

  const { mutate: updateTag, isLoading, error } = useUpdateTag();

  const handleUpdateTag = (newTag: Tag) => {
    updateTag(
      {
        body: newTag,
        pathParams: { tagID: tag.id, workspaceID },
      },
      {
        onSuccess: () => {
          setAnchorEl(null);
          refetchTags();
        },
      }
    );
  };

  return (
    <>
      <Popover
        marginThreshold={32}
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <form onSubmit={handleSubmit(handleUpdateTag)}>
          <Stack direction="column" gap={2} p={2}>
            <Typography variant="body2">Save changes to tag?</Typography>
            <Stack gap={2} direction="row">
              {error && (
                <ErrorDisplay error={error} userMessage="failed to edit tag" />
              )}
              {isLoading && <Spinner />}
              {!isLoading && !error && (
                <>
                  <Stack spacing={2}>
                    <TextField
                      label="Tag Name"
                      fullWidth
                      {...register('name')}
                    />
                    <Select
                      labelId="select-label-workflow"
                      label="Select color"
                      {...typeField}
                      sx={{ textTransform: 'capitalize' }}
                      fullWidth
                    >
                      {colourOptions.map((col) => (
                        <MenuItem
                          key={col[0]}
                          value={col[0]}
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {col[1].readableName}
                        </MenuItem>
                      ))}
                    </Select>
                    <TrackedButton
                      onClick={() => {
                        setAnchorEl(null);
                      }}
                      variant="outlined"
                      trackLabel="cancel-save-tag"
                    >
                      Cancel
                    </TrackedButton>
                    <TrackedButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      trackLabel="confirm-save-tag"
                      disabled={!formState.isValid}
                    >
                      Save
                    </TrackedButton>
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
        </form>
      </Popover>
      <EditButton
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        trackLabelVariant={trackLabelVariant}
        sx={{ color: defaultTheme.palette.neutral.main }}
      />
    </>
  );
};

export default EditTagPopover;
