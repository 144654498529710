import MUIModal from '@mui/material/Modal';
import { ModalProps as MUIModalProps } from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';

export interface ModalProps extends MUIModalProps {
  onClose: () => void;
  title: string;
  maxWidth?: boolean;
  titleColor?: DialogTitleProps['color'];
}

export const Modal = ({
  onClose,
  title,
  maxWidth,
  children,
  titleColor,
  ...modalProps
}: ModalProps): JSX.Element => {
  return (
    <MUIModal {...modalProps}>
      <Dialog
        open
        onClose={onClose}
        fullWidth
        maxWidth={maxWidth ? 'lg' : 'sm'}
      >
        <DialogTitle color={titleColor ?? 'inherit'} variant="h6">
          {title}
        </DialogTitle>
        <DialogContent>
          <Box mt={1}>{children}</Box>
        </DialogContent>
      </Dialog>
    </MUIModal>
  );
};
