import { categorical } from '@automata/mui-theme/src/colors';

/**
 * Select a random categorical color from the theme
 * @returns {string} A random categorical color
 */
export const selectRandomCategoricalColor = (): string => {
  const categoricalColors = Object.keys(categorical);
  const randomCategoricalIndex = Math.floor(
    Math.random() * categoricalColors.length
  );
  return categoricalColors[randomCategoricalIndex];
};
