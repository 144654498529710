import { ReactNode, useState } from 'react';
import { useCreateTag, useGetWorkspaceTags } from '@automata/api/apiComponents';
import { ErrorDisplay, Spinner } from '@automata/ui';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import { useWorkspaceID } from 'hooks/useWorkspaceID';
import TagsList from './TagsList';
import Stack from '@mui/material/Stack';
import { TagsSearch } from './TagsSearch';
import { TagCreator } from './TagCreator';
import { logger, selectRandomCategoricalColor } from '@automata/utils';
import { enqueueSnackbar } from '@automata/rexports/notistack';

type DialogWithTitleProps = {
  open: boolean;
  maxWidth?: DialogProps['maxWidth'];
  onClose?: DialogProps['onClose'];
  children?: ReactNode;
};

export type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const DialogWithTitle = ({ open, onClose, children }: DialogWithTitleProps) => (
  <Dialog open={open} onClose={onClose} maxWidth="lg">
    <DialogTitle sx={{ p: 2, pb: 0 }} variant="subtitle1">
      Tag manager
    </DialogTitle>
    {children}
  </Dialog>
);

export const TagManager = ({ isOpen, onClose }: Props): JSX.Element | null => {
  const workspaceID = useWorkspaceID();
  const [searchString, setSearchString] = useState('');
  const [showArchived, setShowArchived] = useState(false);

  const {
    data,
    isLoading: isLoadingAllTags,
    error: tagsError,
    refetch: refetchTags,
  } = useGetWorkspaceTags(
    {
      pathParams: {
        workspaceID: workspaceID ?? '',
      },
    },
    { enabled: !!workspaceID }
  );

  const { mutate: addNewTag } = useCreateTag();

  const handleAddNewTag = (name: string, onSuccess?: () => void) => {
    addNewTag(
      {
        pathParams: { workspaceID: workspaceID ?? '' },
        body: { name, color: selectRandomCategoricalColor() },
      },
      {
        onSuccess: () => {
          refetchTags();
          onSuccess && onSuccess();
        },
        onError: (error: unknown) => {
          logger.error('Failed to create new tag', error);
          enqueueSnackbar({
            message: 'Failed to create new tag',
            variant: 'error',
          });
        },
      }
    );
  };

  // Needed to prevent the component from mounting on menu open
  if (!isOpen) return null;

  const dialogProps: Omit<DialogProps, 'children'> = {
    open: isOpen,
    onClose,
    maxWidth: 'lg',
  };

  if (isLoadingAllTags) {
    return (
      <DialogWithTitle {...dialogProps}>
        <Spinner />
      </DialogWithTitle>
    );
  }

  if (tagsError || !workspaceID) {
    return (
      <DialogWithTitle {...dialogProps}>
        <ErrorDisplay error={tagsError} userMessage="Unable to fetch tags" />
      </DialogWithTitle>
    );
  }

  const unarchivedTags = data.tags.filter((tag) => !tag.is_archived);
  const archivedTags = data.tags.filter((tag) => tag.is_archived);

  return (
    <DialogWithTitle {...dialogProps}>
      <Stack width="572px" height="475px" p={2} gap={3}>
        <TagCreator existingTags={data.tags} addTag={handleAddNewTag} />
        <Divider />
        <Stack>
          <TagsSearch
            searchString={searchString}
            onSearchStringChange={setSearchString}
            showArchived={showArchived}
            onShowArchivedChange={() => {
              setShowArchived(!showArchived);
            }}
          />
          <Stack overflow="scroll" gap={3} maxHeight="300px" pt={2}>
            {showArchived && (
              <TagsList
                title="Archived tags"
                refetchTags={refetchTags}
                tags={archivedTags}
                searchString={searchString}
              />
            )}
            <TagsList
              title={showArchived ? 'Tags' : undefined}
              refetchTags={refetchTags}
              tags={unarchivedTags}
              searchString={searchString}
            />
          </Stack>
        </Stack>
      </Stack>
    </DialogWithTitle>
  );
};
