import { useState } from 'react';
import { TrackedIconButton } from '@automata/ui';
import { Typography } from '@automata/ui';
import { defaultTheme } from '@automata/mui-theme';
import SettingsIconOutlined from '@mui/icons-material/SettingsOutlined';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import MenuList from '@mui/material/MenuList';
import { SxStyles } from 'types/global';
import { SettingsMenuItem } from './SettingsMenuItem';
import useUserV2 from 'hooks/useUserV2';
import { PermissionLevelsEnum, PermissionsEnum } from 'hooks/useUserV2/types';
import { Versions } from './Versions';
import { useFlag } from 'hooks/unleashHooks';
import { TagManager } from 'components/TagManager';

const styles = {
  icon: {
    color: defaultTheme.palette.grey[700],
  },
  menu: {
    borderRadius: 2,
    maxWidth: 344,
  },
} satisfies SxStyles;

export const SettingsMenu = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tagManagerOpen, setTagManagerOpen] = useState(false);
  const open = Boolean(anchorEl);
  const user = useUserV2();
  const isAdmin =
    user?.data?.permissions?.get(PermissionsEnum.ADMIN_RESOURCES)?.level ===
    PermissionLevelsEnum.WRITE;
  const isTagManagerEnabled = useFlag('fe.tags');

  const handleClickIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TrackedIconButton
        trackLabel="settingsMenu.toggle"
        aria-label="settings menu button"
        onClick={handleClickIcon}
      >
        <SettingsIconOutlined sx={styles.icon} />
      </TrackedIconButton>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClosePopover}
        onClick={handleClosePopover}
        PaperProps={{ sx: styles.menu }}
      >
        <Stack p={2}>
          <Typography variant="subtitle1">Settings</Typography>
          <Versions />
        </Stack>
        <MenuList>
          {isAdmin && [
            <SettingsMenuItem
              key="settings-menu-item-users"
              route="users"
              title="Users"
              description="Manage and add new users"
            />,
            <SettingsMenuItem
              key="settings-menu-item-auditLogs"
              route="auditLogs"
              title="Audit Logs"
              description="Visualise and download audit logs"
            />,
          ]}
          {isTagManagerEnabled && (
            <SettingsMenuItem
              title="Tagging"
              description="Manage Tags"
              onClick={() => {
                setTagManagerOpen(true);
              }}
            />
          )}
        </MenuList>
      </Popover>
      {isTagManagerEnabled && (
        <TagManager
          isOpen={tagManagerOpen}
          onClose={() => {
            setTagManagerOpen(false);
          }}
        />
      )}
    </>
  );
};
